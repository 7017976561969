<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담사 상세 급여정보</h2>
                <br>
                        <h3><span>상담사명 : {{staffName}}</span></h3>
                <br>
                        <h3><span>센터 : {{centerName}}</span></h3>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <a class="btn_sms pointer" @click="cancel()">목록</a>
                        <select name="birth-yyyy" @change="getStaffPay()" v-model="startYY" class="ml-10" id="yyyy">
                                <option v-for="year in parseYear()" :key="year" :value="year">{{year}}년</option>
                            </select>
                    </div>
                    <table class="default_table mt-20 mb-00">
                        <tbody>
                            <tr>
                                <th>년도/월</th>
                                <th>상담회기</th>
                                <th>매출액<br>(미수금포함)</th>
                                <th>총 급여</th>
                                <th>소득세</th>
                                <th>지방소득세</th>
                                <th>실지급액</th>
                            </tr>
                            <tr v-for="(item, index) of payList" :key="index">
                                <td>{{item.permissionMonth}}</td>
                                <td>{{item.reportCnt}}</td>
                                <td>{{numberWithCommas(item.price + item.etcPrice)}}</td>
                                <td>{{numberWithCommas(item.staffPay)}}</td>
                                <td>{{numberWithCommas(item.incomeTax)}}</td>
                                <td>{{numberWithCommas(item.localIncomeTax)}}</td>
                                <td>{{numberWithCommas(item.realStaffPay)}}</td>
                            </tr>
                            <tr v-if="payList.length === 0">
                                <td colspan="7">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="default_table mt-20 mb-00">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>총 상담회기</th>
                                <th>총 매출액<br>(미수금포함)</th>
                                <th>총 급여</th>
                                <th>총 소득세</th>
                                <th>총 지방소득세</th>
                                <th>총 실지급액</th>
                            </tr>
                            <tr>
                                <td>합계</td>
                                <td>{{payTotal.reportCnt}}</td>
                                <td>{{numberWithCommas(payTotal.price + payTotal.etcPrice)}}</td>
                                <td>{{numberWithCommas(payTotal.staffPay)}}</td>
                                <td>{{numberWithCommas(payTotal.incomeTax)}}</td>
                                <td>{{numberWithCommas(payTotal.localIncomeTax)}}</td>
                                <td>{{numberWithCommas(payTotal.realStaffPay)}}</td>
                            </tr>
                            <tr v-if="payTotal.length === 0">
                                <td colspan="7">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="default_table mt-20 mb-00">
                        <tbody>
                            <tr>
                                <th>내담자</th>
                                <th>상담구분</th>
                                <th>상담일</th>
                                <th>일지승인일</th>
                                <th>소득세</th>
                                <th>지방소득세</th>
                                <th>실지급액</th>
                            </tr>
                            <tr v-for="(item, index) of payDetailList" :key="index">
                                <td class="underline pointer" @click="detailCustomerChart(item.idxCrmClient)">{{item.clientName}}</td>
                                <td>{{item.gubun}} ({{item.productName}}) </td>
                                <td>{{item.consDate}}</td>
                                <td>{{item.permissionDate}}</td>
                                <td>{{numberWithCommas(item.incomeTax)}}</td>
                                <td>{{numberWithCommas(item.localIncomeTax)}}</td>
                                <td>{{numberWithCommas(item.realStaffPay)}}</td>
                            </tr>
                            <tr v-if="payDetailList.length === 0">
                                <td colspan="7">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        staffName: '',
        centerName: '',
        idx: -1,
        startYY: '',
        payList: [],
        payTotal: "",
        payDetailList: [],
        idxCrmCenter: -1,

    }),

    mounted() {

        let date = new Date()
        this.startYY = date.getFullYear()
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getStaffPay(this.idx)
    },

    methods: {

        // 급여 사아앙세목록
        getPayStaffDetail(idx) {
            
            let params = {
                date: this.startYY + "",
                idxCrmCenter: this.idxCrmCenter 
            }

            this.axios.get(`/api/v1/pay/staff/detail/${idx}`, {
                params: params
            })
            .then(res => {
                    if (res.data.err === 0) {
                        this.payDetailList = res.data.payList
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.payDetailList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },
        
        getStaffPay(idx) {

            idx = this.idx
            let params = {
                date: this.startYY + ""
            }


            this.axios.get(`/api/v1/pay/staff/${idx}`, {
                params: params
            })
                .then(res => {
                    if (res.data.err === 0) {
                        
                        this.payList = res.data.payList
                        this.payTotal = res.data.payTotal
                        this.staffName = res.data.payList[0].staffName
                        this.centerName = res.data.payList[0].centerName
                        this.idxCrmCenter = res.data.staff.idxCrmCenter

                        this.getPayStaffDetail(idx)

                    } else {
                        this.payList = []
                        this.payTotal = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 이전 페이지로 이동
        cancel() {
            history.back()
        },

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        // 현재 년도에 따라서 120년만큼 selectbox
        parseYear() {
            var nowYear = new Date().getFullYear()
            var parseYear = []
            for (var i = 0; i <= 120; i++) {
                parseYear.push(nowYear - i)
            }
            return parseYear
        },

        /**
         * @description : 내담자 정보 Detail
         */
        detailCustomerChart(idx){
            this.$router.push(`/origin/customer_manage_chart?idx=${idx}`)
        }

    }
}
</script>
